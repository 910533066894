<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
        'apcDelcon_selectOrder',
        ])"></div>

        <MultiSelect
            class="white mb-3"
            :options="ordersFBM"
            :label="$t('apcDelcon_selectOrder.localization_value.value')"
            :otherValue="'APCDelcon'"
            :selected="APCD.data.relatedOrders"
            :searchable="true"
            @change="changeSelectedOrders"
        />

      </div>
    </div>

  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
import {FBMMixins} from "../../../../../mixins/FBMMixins/FBMMixins";
import MultiSelect from "../../../../UI/selectiones/MultiSelect/MultiSelect";

export default {
  name: "OrdersFBMBlock",

  components: {
    MultiSelect
  },

  props: {
    APCD: Object,
    ordersFBM: Array,
  },

  mixins: [
    mixinDetictingMobile,
    FBMMixins,
  ],

  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

    changeSelectedOrders(val) {
      this.APCD.setRelatedOrders(val, this)
    },

    removeOrder(id) {
      this.APCD.setRelatedOrders(this.APCD.getRelatedOrders().filter(item => {
        return item.id !== id
      }))
    }
  },
}
</script>

<style lang="scss" scoped>
  .site-table thead th,
  .site-table tbody td {
    padding-right: 5px;
    padding-left: 5px;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 0!important;
  }

  .table-manage-list .manager-btn {
    max-width: 200px;
  }

  .table-manage-list.table-right {
    justify-content: flex-end;
  }

  .order-create__section-label.ui-no-valid {
    color: #F2994A;
    position: relative;
  }

  @media (min-width: 1600px){
    .site-table .ellipsis {
      max-width: 70%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
</style>